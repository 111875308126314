import React from 'react';



const VideoModal = ({channel, isOpen, videoId, onClose}) => {
    return(
        <div className="modal-video-wrapper">

        </div>
    )
}

export default VideoModal;
